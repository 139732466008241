import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HereApiService {
  private baseUrl = 'https://router.hereapi.com/v8/routes';
  private apiKey = 'cLb9PqfAt0VbrxX3Qlqcr-P63DUV703ndu2Qahj1hGM'; // Replace with your actual API key

  constructor(private http: HttpClient) {}

  getRoutes(start: string, end: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const params = new HttpParams()
      .set('apiKey', this.apiKey)
      .set('transportMode', 'car') // You can change the transport mode if needed
      .set('origin', start)
      .set('destination', end)
      .set('return', 'polyline,turnByTurnActions,actions,instructions,travelSummary');

    return this.http.get(`${this.baseUrl}`, { headers, params });
  }

  getRoutesViaPoints(vehicle:string,start:string, end:string, viaPoints:string[], passtrough: boolean){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let params = new HttpParams()
      .set('apiKey', this.apiKey)
      .set('transportMode', vehicle) 
      .set('origin', start)
      .set('destination', end)
      .set('return', 'polyline,turnByTurnActions,actions,instructions,travelSummary');

      viaPoints.forEach((viaPoint, index) => {
        console.log('PARAMS',viaPoint)
        
        if(index== viaPoints.length -1){
          if(passtrough){
            params = params.append('via', viaPoint+'!passThrough=true')
          }
        }
        else{
          params = params.append('via', viaPoint);
        }
      });
    
      
    console.log('params',params.toString())
    return this.http.get(`${this.baseUrl}`, { headers, params });

  }
}
