export enum Services {
	HOME = 1,
	MY_FIELDS = 2,
	AGROANALYTICS = 3,
	AGRODAT = 4,
	AGRODRONE = 5,
	AGROMET = 6,
	AGROMONITOR = 7,
	ABOUT = 8,
	TEST = 9,
	AGROMEETING = 10
}
