import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StadisticsStore, StadisticsObject, StadisticsSingleObject } from './stadistics.store';
import { StadisticsQuery } from './stadistics.query';
import { FieldsQuery } from '@store/fields/fields.query';
import { FieldsStore, Field } from '@store/fields/fields.store';
import { environment } from '@src/environments/environment';
import { arrayAdd } from '@datorama/akita';
import { SharedMapData } from '@src/app/modules/my-fields/modules/fields/components/main-map/sharedMapData';
import { SubVarietyQuery } from '../subVariety/state/sub-variety.query';
import { UserService } from '../user/user.service';

export interface StadisticsResponse {
    statusCode: number;
    error: string;
    message: {
        detail: StadisticsSingleObject[];
        status: string;
    };
}

export interface MetereologyResponse {
    statusCode: number;
    error: string;
    message: MetereologyObject;
}

export interface MetereologyObject {
    clouds: number;
    humidity: number;
    temperature: number;
    temperatureC: number;
    wind: number;
}

@Injectable({ providedIn: 'root' })
export class StadisticsService {
    public constructor(
        private readonly http: HttpClient,
        private readonly stadisticsStore: StadisticsStore,
        private readonly stadisticsQuery: StadisticsQuery,
    ) {}

    public async getEstadisticsInformation(lot_id) {
        try {
            var today = new Date();
            var todayS =
                today.getFullYear() +
                '-' +
                String(today.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(today.getDate()).padStart(2, '0');
            var todayA =
                today.getFullYear() -
                1 +
                '-' +
                String(today.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(today.getDate()).padStart(2, '0');
            const rpt = await this.http
                .get<StadisticsResponse>(
                    `${environment.apiUrl}/lots/` + lot_id + '/stadistics/' + todayA + '/' + todayS,
                )
                .toPromise<StadisticsResponse>();
            if (rpt.message.status === 'SUCCESS') {
                this.stadisticsStore.add({
                    id: lot_id,
                    stats: rpt.message.detail,
                });
            }
            return rpt.message;
        } catch (error) {
            console.log(error);
        }
    }

    public async getMetereologyPromCampaing(lot_id: string, campaing_id: string) {
        const rpt = await this.http
            .get<MetereologyResponse>(
                environment.apiUrl + '/agromet/stadistics/lot/' + lot_id + '/campaign/' + campaing_id,
            )
            .toPromise<MetereologyResponse>();
        rpt.message.temperature = Math.round(rpt.message.temperature * 10) / 10;
        rpt.message.temperatureC = Math.round((rpt.message.temperature - 273.15) * 10) / 10;

        return rpt.message;
    }
}
