import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { UserService } from '../user/user.service';
import { SatImageSingleObject, SatImageStore } from './satImgDates.store';
import { SatImageQuery } from './satImgDates.query';

export interface SatImageResponse {
	statusCode: number,
	error: string,
	message: SatImageSingleObject[]
}

@Injectable({ providedIn: 'root' })
export class SatImageService {
	public constructor(
		private readonly http: HttpClient,
		private readonly satImageStore: SatImageStore,
		private readonly userService: UserService
	) { }

	private getTime(datestring?: string) {
		var date = new Date(datestring)
		return date != null ? date.getTime() : 0;
	}

	public async getDatesForField(polygon_Id: string) {
		try {
			var today = new Date();
			var todayS = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
			var todayA = (today.getFullYear() - 1) + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
			const rpt = await this.http.get<SatImageSingleObject>(`${environment.apiUrl}/lots/` + polygon_Id + "/info/" + todayA + "/end/" + todayS, {}).toPromise<SatImageSingleObject>();

			rpt.lotData.sort((a, b) => (this.getTime(a.date) > this.getTime(b.date)) ? -1 : ((this.getTime(b.date) > this.getTime(a.date)) ? 1 : 0))

			this.userService.updatePermissionsUser(0, +rpt.space)
			this.satImageStore.add(
				{
					id: polygon_Id,
					satImgDate: rpt
				}
			)
			return rpt;
		} catch (error) {
			console.log(error);
		}
	}

	public async getDatesForImage(coordinates: number[][]) {
		var today = new Date();
		var todayS = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
		var todayA = (today.getFullYear() - 1) + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

		let parameters = {
			"coordinates": coordinates,
			"start_date": todayA,
			"end_date": todayS,
			"cloud_cover": 100
		}
		try {
			const data = await this.http.post<any>(`${environment.apiUrl}/satellite-image/filter-images`, parameters).toPromise<any>();
			return data.message;
			
		} catch (error) {
			return undefined;
		}
	}
	
	public async sendRequestImage(data : any) {
		try {
			const rpt = await this.http.post<any>(`${environment.apiUrl}/generate/request`, data ).toPromise<any>();
			return rpt;
		} catch (error) {
			console.log(error);
		}
	}
}
