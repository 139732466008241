import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { FieldsRoutingModule } from './fields-routing.module';

// Sidebar
import { SidebarModule } from 'ng-sidebar';

// Pages
import { MainPageComponent } from './pages';

// Components
import { MainMapComponent, DialogConfirmationLot, NoMoreSpaceDialog } from './components/main-map/main-map.component';
import {
  FieldListComponent,
  FieldDeleteDialog,
  LotDeleteDialog,
  CannotDeleteDialog,
  FieldIndicesDialog,
} from './components/field-list/field-list.component';
import { CreateFieldComponent } from './sidebar-components/create-field/create-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateLotComponent } from './sidebar-components/create-lot/create-lot.component';
import { LotDetailComponent, LotDetailDeleteConfirmation } from './sidebar-components/lot-detail/lot-detail.component';
import { SharedMapData } from './components/main-map/sharedMapData';
import { LineGraphComponent } from './sidebar-components/line-graph/line-graph.component';
import { DonutGraphComponent } from './sidebar-components/donut-graph/donut-graph.component';
import { ChartsModule } from 'ng2-charts';
import { EditFieldComponent } from './sidebar-components/edit-field/edit-field.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { PhenologicalStateComponent } from './sidebar-components/phenological-state/phenological-state.component';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { AgromonitorModule } from '@src/app/modules/agromonitor/agromonitor.module';
import { IncidenceChartComponent } from './components/incidence-chart/incidence-chart.component';
import { BoundariesStateComponent } from './sidebar-components/boundaries-state/boundaries-state.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditCampingComponent } from './components/edit-camping/edit-camping.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HistoricalCampaingComponent } from './sidebar-components/lot-detail/historical-campaing/historical-campaing.component';
import { CampaingDetailComponent } from './sidebar-components/lot-detail/campaing-detail/campaing-detail.component';
import { LaborChartComponent } from './sidebar-components/lot-detail/labor-chart/labor-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AngularResizedEventModule } from 'angular-resize-event';
import { StatisticsChartComponent } from './sidebar-components/lot-detail/statistics-chart/statistics-chart.component';
import { DropdownModule } from 'primeng/dropdown';
import { SubmissionRequestsComponent } from '../../components/submission-requests/submission-requests.component';
import { RequestReceivedComponent } from '../../components/request-received/request-received.component';
import { CollaboratorPermissionsComponent } from '../../components/collaborator-permissions/collaborator-permissions.component';
import { SharedFieldListComponent } from '../../components/shared-field-list/shared-field-list.component';
import { SharedLotDetailComponent } from '../../components/shared-lot-detail/shared-lot-detail.component';
import {
  CollaboratorInformationComponent,
  CollaboratorPermissions,
  CollaboratorWithDrawalConfirmation,
} from '../../components/collaborator-information/collaborator-information.component';
import { AngularSplitModule } from 'angular-split';
import { ComparisonDialogComponent } from './sidebar-components/lot-detail/comparison-dialog/comparison-dialog.component';
import { DonutResourceComponent } from './sidebar-components/lot-detail/historical-campaing/donut-resource/donut-resource.component';
import { DiseaseInformationDialog } from './components/main-map/disease-info-dialog';
import { DialogSelectLot } from './components/main-map/dialog-select-lot';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AppChartComponent } from '../../chart/chart.component';
import { AppItemChartComponent } from '../../item-chart/item-chart.component';
@NgModule({
  exports: [MatButtonModule, MatDialogModule],
  imports: [
    CommonModule,
    FieldsRoutingModule,
    ReactiveFormsModule,
    // Sidebar
    SidebarModule,
    ChartsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    NgxSpinnerModule,
    MatTooltipModule,
    MatSelectModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    FormsModule,
    AgromonitorModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    InputTextModule,
    ButtonModule,
    MatExpansionModule,
    DragDropModule,
    NgApexchartsModule,
    AngularResizedEventModule,
    DropdownModule,
    AngularSplitModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule
    
  ],
  declarations: [
    MainPageComponent,
    MainMapComponent,
    DialogConfirmationLot,
    FieldListComponent,
    FieldDeleteDialog,
    LotDeleteDialog,
    CreateFieldComponent,
    CreateLotComponent,
    LotDetailComponent,
    LotDetailDeleteConfirmation,
    LineGraphComponent,
    DonutGraphComponent,
    EditFieldComponent,
    PhenologicalStateComponent,
    IncidenceChartComponent,
    BoundariesStateComponent,
    EditCampingComponent,
    CannotDeleteDialog,
    FieldIndicesDialog,
    NoMoreSpaceDialog,
    HistoricalCampaingComponent,
    CampaingDetailComponent,
    LaborChartComponent,
    StatisticsChartComponent,
    SubmissionRequestsComponent,
    RequestReceivedComponent,
    CollaboratorPermissionsComponent,
    SharedFieldListComponent,
    SharedLotDetailComponent,
    CollaboratorInformationComponent,
    CollaboratorWithDrawalConfirmation,
    CollaboratorPermissions,
    ComparisonDialogComponent,
    DonutResourceComponent,
    DiseaseInformationDialog,
    DialogSelectLot,
    AppChartComponent,
    AppItemChartComponent
  ],
  providers: [
    SharedMapData,
    MainMapComponent,
    
    DatePipe,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  entryComponents: [],
})
export class FieldsModule {}
