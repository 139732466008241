import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormBuilder,
	Validators,
	AbstractControl,
} from '@angular/forms';

import { FieldsService } from '@src/app/store/fields/fields.service';
import { FieldsUiService } from '../../services/fields-ui.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { SharedMapData } from '@src/app/modules/my-fields/modules/fields/components/main-map/sharedMapData'
import { Lot } from '@src/app/store/lots/lots.store';
import { LotsService } from '@src/app/store/lots/lots.service';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';
import { LotCropServiceService } from '@src/app/modules/my-fields/services/lot-crop-service/lot-crop-service.service';
import { FielListQuery } from '@src/app/modules/my-fields/store/field-list/fiel-list.query';
import { FielListService } from '@src/app/modules/my-fields/store/field-list/fiel-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';
import { VarietyQuery } from '@src/app/store/variety/state/variety.query';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { MainPageComponent } from '../../pages/main-page/main-page.component'
@Component({
	selector: 'app-create-lot',
	templateUrl: './create-lot.component.html',
	styleUrls: ['./create-lot.component.scss']
})
export class CreateLotComponent implements OnInit {

	public form: FormGroup;
	public fields = this.fieldsQuery.selectFieldsWithLots();
	public listOfCrops = [];
	public listOfCropsVarieties = [];
	public selectedIdCrop;
	public isTrySend: boolean = false;
	public fieldId = "Asignar a campo"
	constructor(
		private readonly formBuiilder: FormBuilder,
		private readonly fieldsUiService: FieldsUiService,
		private fieldsQuery: FieldsQuery,
		private sharedMapData: SharedMapData,
		private lotsService: LotsService,
		public lotCropService: LotCropServiceService,
		private mapSidebarComService: MapSidebarComService,
		private readonly spinner: NgxSpinnerService,
		private readonly subVarietyQuery: SubVarietyQuery,
		private readonly varietyQuery: VarietyQuery,
		private readonly globalToastService: GlobalToastService,
		public mainPageComponent: MainPageComponent
	) {
		this.varietyQuery.selectAll().subscribe(elem => {
			this.listOfCrops = elem;
		});

		this.subVarietyQuery.selectAll().subscribe(elem => {
			this.listOfCropsVarieties = elem
		});
		this.fieldsQuery.selectActiveId().subscribe((idField) => {
			if (idField !== undefined) {
				this.fieldId = idField
			} else {
				this.fieldId = "Asignar a campo"
			}
		})
	}

	public async registerField() {
		this.isTrySend = true;
		this.spinner.show('Syncrof');
		if (this.form.invalid) {
			this.spinner.hide('Syncrof');
			return;
		}
		if (this.form.value.fieldSelected == "Asignar a campo") {
			this.globalToastService.createErrorToast('Error', 'Seleccione un campo para el lote');
			this.spinner.hide('Syncrof');
			return;
		}
		var fieldId = this.form.value.fieldSelected;
		var lot: Lot = {
			coordinates: this.sharedMapData.polygonCoords,
			name: this.form.value.name,
			area: this.sharedMapData.polygonArea,
			center: this.sharedMapData.polygonCenter,
			varietyId: this.form.value.varietyId
		}
		await this.lotsService.createLot(fieldId, lot);
		this.mapSidebarComService.sentClickDeleteAllDrawedTemporalPolygons();
		this.mainPageComponent.showFieldList = true;
		this.fieldsUiService.closeSidebar();
		this.spinner.hide('Syncrof');
		this.mapSidebarComService.sendClickToDeselect();
	}

	public get fname(): AbstractControl {
		return this.form.get('name');
	}

	ngOnInit() {
		this.form = this.formBuiilder.group({
			name: ['', [Validators.required, Validators.maxLength(50)]],
			fieldSelected: [this.fieldId, Validators.required],
			fieldType: ['Cultivo a explotar'],
			varietyId: ['Variedad de cultivo'],
		});
	}

	public closeSidebar() {
		this.mainPageComponent.showFieldList = true;
		this.fieldsUiService.closeSidebar();
		this.mapSidebarComService.sentClickDeleteAllDrawedTemporalPolygons();
		this.mapSidebarComService.sendClickToDeselect();
	}

	public changeIdOfCrop() {
		this.selectedIdCrop = this.form.value.fieldType;
	}
}
