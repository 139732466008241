import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@src/environments/environment';

import { FieldMockService } from '@shared/mocks/fields-mock.service';

import { LotsStore } from '@store/lots/lots.store';
import { LotsService } from '@store/lots/lots.service';

import { FieldsStore, Field } from './fields.store';
import { FieldsQuery } from './fields.query';
import { UserQuery } from '../user/user.query';
import { SubVarietyService } from '../subVariety/state/sub-variety.service';
import { SubVarietyQuery } from '../subVariety/state/sub-variety.query';
import { PhenologicalStateService } from '@src/app/modules/my-fields/store/phenological-state-storage/phenological-state.service';
import { UserService } from '../user/user.service';
import { forkJoin } from 'rxjs';

export interface Stats {
    average: number;
    max: number;
    median: number;
    min: number;
    p10: number;
    p90: number;
    q1: number;
    q3: number;
    std: number;
    variance: number;
}
export interface IndexInfo {
    cloud: number;
    date: string;
    indexes: {
        NDVI: Stats;
        NDWI: Stats;
        SAVI: Stats;
    };
    notes: [string];
    scene_id: string;
    view_id: string;
}
export interface EOSRpt {
    errors: [{}];
    message: LotInfo[];
}

export interface LotInfo {
    lotId: string;
    name: string;
    date: string;
    statistics: stadistic[];
}
export interface stadistic {
    average: number;
    name: string;
}

export interface SchemaRPT {
    statusCode: number;
    message: {
        boundaries: [];
        lot_image: {
            id: number;
            lot_id: string;
            image_url: string;
            sides: number;
        };
    };
}
@Injectable({ providedIn: 'root' })
export class FieldsService {
    public constructor(
        private readonly http: HttpClient,
        private readonly fieldsMockService: FieldMockService,
        private readonly fieldsStore: FieldsStore,
        private readonly lotsStore: LotsStore,
        private readonly lotsService: LotsService,
        private readonly fieldsQuery: FieldsQuery,
        private readonly userService: UserService,
        private readonly subVarietyService: SubVarietyService,
        private readonly subVarietyQuery: SubVarietyQuery,
        private phenologicalStateService: PhenologicalStateService,
    ) {}

    // TODO: Reemplazar los mock con llamadas al servidor

    public async findAllWithMock() {
        const data = await this.fieldsMockService.findAll();

        const allLots = [];

        const fields = data.map((currentField: any) => {
            const { lots, ...field } = currentField;
            field.lots = [];

            for (const lot of lots) {
                allLots.push(lot);
                field.lots.push(lot.id);
            }

            return field;
        });

        this.fieldsStore.set(fields);
        this.lotsStore.set(allLots);
    }

    public async findAll(): Promise<Field[]> {
        const hasCache = this.fieldsQuery.getHasCache();
        if (hasCache) {
            return;
        }
        await this.subVarietyService.findAll();
        await this.phenologicalStateService.findAll();
        try {
            const data = await this.http.get<Field[]>(`${environment.apiUrl}/fields`).toPromise<Field[]>();

            const allLots = [];

            const fields = data.map((currentField: any) => {
                const { lots, ...field } = currentField;
                field.id = String(field.id);
                field.lots = [];

                for (const lot of lots) {
                    lot.id = String(lot.id);
                    lot.fieldId = field.id;
                    lot['area'] = Math.round(lot['area'] * 100) / 100;
                    //Exchange cords from lat long to long lat
                    for (let index = 0; index < lot['coordinates'].length; index++) {
                        var tmp = lot['coordinates'][index][0];
                        lot['coordinates'][index][0] = lot['coordinates'][index][1];
                        lot['coordinates'][index][1] = tmp;
                    }
                    lot['varietyName'] = '';
                    lot['subVarietyName'] = '';
                    var lotVarietyId = undefined;
                    for (let index = 0; index < lot.campaigns.length; index++) {
                        if (!lot.campaigns[index].isFinished) {
                            lotVarietyId = lot.campaigns[index].varietyId;
                        }
                    }
                    if (lotVarietyId !== undefined) {
                        var tmpVarietyInformation = this.subVarietyQuery.getEntity(lotVarietyId);
                        if (tmpVarietyInformation !== undefined) {
                            lot['varietyName'] = tmpVarietyInformation.product.name;
                            lot['subVarietyName'] = tmpVarietyInformation.name;
                        }
                    }
                    allLots.push(lot);
                    field.lots.push(lot.id);
                }
                return field;
            });
            this.fieldsStore.set(fields);
            this.lotsStore.set(allLots);

            return fields;
        } catch (error) {}
    }

    public asyncFindAll() {
        const hasCache = this.fieldsQuery.getHasCache();
        if (hasCache) {
            return;
        }
        const observables = [this.subVarietyService.findAll(), this.phenologicalStateService.findAll()];
        forkJoin(observables).subscribe((results: any) => {
            this.http.get<Field[]>(`${environment.apiUrl}/fields`).subscribe((response) => {
                const allLots = [];
                const fields = response.map((currentField: any) => {
                    const { lots, ...field } = currentField;
                    field.id = String(field.id);
                    field.lots = [];

                    for (const lot of lots) {
                        lot.id = String(lot.id);
                        lot.fieldId = field.id;
                        lot['area'] = Math.round(lot['area'] * 100) / 100;
                        //Exchange cords from lat long to long lat

                        for (let index = 0; index < lot['coordinates'].length; index++) {
                            var tmp = lot['coordinates'][index][0];
                            lot['coordinates'][index][0] = lot['coordinates'][index][1];
                            lot['coordinates'][index][1] = tmp;
                        }
                        lot['varietyName'] = '';
                        lot['subVarietyName'] = '';
                        var lotVarietyId = undefined;
                        for (let index = 0; index < lot.campaigns.length; index++) {
                            if (!lot.campaigns[index].isFinished) {
                                lotVarietyId = lot.campaigns[index].varietyId;
                            }
                        }
                        if (lotVarietyId !== undefined) {
                            var tmpVarietyInformation = this.subVarietyQuery.getEntity(lotVarietyId);
                            if (tmpVarietyInformation !== undefined) {
                                lot['varietyName'] = tmpVarietyInformation.product.name;
                                lot['subVarietyName'] = tmpVarietyInformation.name;
                            }
                        }
                        allLots.push(lot);
                        field.lots.push(lot.id);
                    }
                    return field;
                });
                this.fieldsStore.set(fields);
                this.lotsStore.set(allLots);
            });
        });
    }

    public async createOne(name: string, color: string) {
        try {
            const field = await this.http
                .post<Field>(`${environment.apiUrl}/fields`, {
                    name,
                    color,
                })
                .pipe(
                    map((x) => {
                        x.id = String(x.id);
                        return x;
                    }),
                )
                .toPromise<Field>();

            this.fieldsStore.add({
                id: field.id,
                name: field.name,
                lots: [],
                color: field.color,
            });
        } catch (error) {}
    }

    public async removeField(idField: string) {
        try {
            const rpt = await this.http.delete<any>(`${environment.apiUrl}/fields/` + idField).toPromise();
            this.fieldsQuery.selectField(idField).subscribe((field) => {
                if (field.lots != undefined)
                    field.lots.forEach((idLot) => {
                        this.lotsService.deleteLotInStore(idLot);
                    });
                this.fieldsStore.remove(idField);
            });

            this.userService.updatePermissionsUser(rpt.message.area, rpt.message.space);
        } catch {}
    }

    public setActiveField(fieldId: string) {
        this.fieldsStore.setActive(fieldId);
    }

    public async updateField(idField: string, name: string, color: string) {
        try {
            const rpt2 = await this.http
                .put<Field>(`${environment.apiUrl}/fields`, {
                    id: idField,
                    name: name,
                    color: color,
                })
                .toPromise();
            this.fieldsStore.updateActive({
                name: name,
                color: color,
            });
        } catch {}
    }

    public async getDatesForField(polygon_Id: string) {
        try {
            var today = new Date();
            var todayS =
                today.getFullYear() +
                '-' +
                String(today.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(today.getDate()).padStart(2, '0');
            var todayA =
                today.getFullYear() -
                1 +
                '-' +
                String(today.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(today.getDate()).padStart(2, '0');
            const rpt = await this.http
                .get<any>(`${environment.apiUrl}/lots/` + polygon_Id + '/info/' + todayA + '/end/' + todayS, {})
                .toPromise();
            this.userService.updatePermissionsUser(0, +rpt.space);
            return rpt;
        } catch (error) {
            console.log(error);
        }
    }

    public async getSchema(id: string) {
        try {
            const rpt = await this.http.get<SchemaRPT>(`${environment.apiUrl}/lindero/` + id).toPromise<SchemaRPT>();
            return rpt;
        } catch (error) {
            return error;
        }
    }

    public async updateSchema(data, id) {
        try {
            data.lot_id = null;
            const rpt = await this.http.put<SchemaRPT>(`${environment.apiUrl}/lindero`, data).toPromise<SchemaRPT>();
            return rpt;
        } catch (error) {
            return error;
        }
    }

    public async getPDF(lotID, data) {
        const rpt = await this.http
            .post(`${environment.apiUrl}/lots/${lotID}/report`, data, { responseType: 'blob' })
            .toPromise();
        return rpt;
    }
}
