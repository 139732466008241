import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserStore } from './user.store';

import { environment } from '@src/environments/environment';

import { User } from '@shared/entities/user/user.entity';

// Queries
import { UserQuery } from './user.query';
import { AuthQuery } from '../auth/auth.query';

@Injectable({ providedIn: 'root' })
export class UserService {
  public constructor(
    private readonly http: HttpClient,
    private readonly userStore: UserStore,
    private readonly userQuery: UserQuery,
    private readonly authQuery: AuthQuery,
  ) {}

  public async loadUserData() {
    if (this.userQuery.getHasCache()) {
      return;
    }

    const user = await this.http.get<User>(`${environment.apiUrl}/users/info`).toPromise<User>();
    user.id = String(user.id);
    this.userStore.update((state) => ({
      ...user,
    }));
  }

  public async updateUserInformation(userId: string, changes: any) {
    try {
      const data = this.authQuery.getValue();
      const user = await this.http
        .put<User>(`${environment.apiUrl}/users`, changes, {
          headers: { Authorization: 'Bearer ' + data.token },
        })
        .toPromise<User>();
      user.id = String(user.id);
      this.userStore.update((state) => ({
        ...user,
      }));
    } catch (error) {}
  }

  public async updatePermissionsUser(usedArea: number, usedMemory: number) {
    try {
      this.userStore.updatePermission(usedArea, usedMemory);
    } catch (error) {}
  }

  public async updatePassword(oldPass, newPass) {
    try {
      const newPassResponse = await this.http
        .put(`${environment.apiUrl}/updatePassword/`, {
          currentPassword: oldPass,
          newPassword: newPass,
        })
        .toPromise();

      return newPassResponse;
    } catch (error) {}
  }

  public async deleteUser(password: string) {
    try {
      const user = await this.http.delete<any>(`${environment.apiUrl}/users/me/` + password).toPromise<any>();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async getUserInfo() {
    try {
      const user = await this.http.get<any>(`${environment.apiUrl}/users/messenger`).toPromise<any>();

      return user.message;
    } catch (error) {}
  }

  public async updateProfileImage(form_data) {
    try {
      const user = await this.http.post<User>(`${environment.apiUrl}/users/image`, form_data).toPromise<User>();
      user.id = String(user.id);
      this.userStore.update((state) => ({
        ...user,
      }));

      return user;
    } catch (error) {
      return undefined;
    }
  }

  public getUserPaymentStatus(userID: string) {
    return this.http.get(`https://app.infoagro.pe/payments/api/subscription/getUserSubscription/${userID}`);
  }
}
