import { Component, OnInit } from '@angular/core';

import { Services } from '@shared/enums/services';
import { SidebarService } from '@store/sidebar/sidebar.service';
import { SidebarQuery } from '@store/sidebar/sidebar.query';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthHttpService } from '@src/app/store/auth/auth-http.service';
import { MapSidebarComService } from '@src/app/modules/my-fields/modules/fields/services/map-sidebar-com.service';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	host: {
		'[class.is-docked]': '!isOpen&&isDocked',
	},
})
export class SidebarComponent {
	public selectedService: Services;
	public isOpen: boolean;
	public isDocked: boolean;
	public choosedService = 1;
	constructor(
		private readonly sidebarService: SidebarService,
		private readonly sidebarQuery: SidebarQuery,
		private readonly router: Router,
		private readonly authService: AuthHttpService,
		private cookieService: CookieService,
		private mapsideService:MapSidebarComService
	) {
		this.sidebarQuery.selectIsOpen.subscribe((x) => {
			this.isOpen = x;
		});
		this.sidebarQuery.selectIsDock.subscribe((x) => {
			this.isDocked = x;
		});

		this.sidebarQuery.selectedService.subscribe((x) => {
			this.selectedService = x;
		});


	}

	onOptionSelect(option: number) {

		this.mapsideService.actualizarVariable(option)
		this.selectedService = option
		this.choosedService = option
		if(option==1 || option==2)
		{
			this.router.navigate(['/my-fields/fields']);
		}
		if(option==3)
		{
			this.router.navigate(['/agromet']);
		}
	

	}
	public selectService(service: Services) {
		this.sidebarService.updateSelectedService(service);

		switch (service) {
			case Services.HOME:
				this.router.navigate(['/home/fields']);
				break;

			case Services.MY_FIELDS:
				this.router.navigate(['/my-fields/fields']);
				break;
			// case Services.AGRODAT:
			// 	this.router.navigate(['/agrodat']);
			// 	break;
			// case Services.AGROANALYTICS:
			// 	this.router.navigate(['/agroanalytics']);
			// 	break;
			case Services.AGROMET:
				this.router.navigate(['/agromet']);
				break;
			case Services.AGROMONITOR:
				this.router.navigate(['/agromonitor']);
				break;
			case Services.AGRODRONE:
				this.router.navigate(['/agrodrone']);
				break;
			// case Services.TEST:
			// 	this.sidebarService.close()
			// 	this.router.navigate(['/agroaprende']);
			// 	break;
			// case Services.AGROMEETING:
			// 	this.sidebarService.close()
			// 	this.router.navigate(['/agromeeting']);
			// 	break;
			default:
				this.router.navigate(['.']);
				break;
		}
	}

	public toggleSidebar(option: number) {

		this.mapsideService.actualizarVariable(option)
		this.selectedService = option
		this.choosedService = option
		if(option==1 || option==2)
		{
			this.router.navigate(['/my-fields/fields']);
		}
		if(option==3)
		{
			this.router.navigate(['/agromet']);
		}
		this.sidebarService.toggle();
	}

	public navigateToProfile() {
        this.router.navigate(['/profile']);
    }
	public simpleTootgleSidebar(){
		this.sidebarService.toggle();
	}

	public openSidebar() {
		this.sidebarService.open();
	}

	public closeSidebar() {
		this.sidebarService.close();
	}

	public logout(): void {
        this.cookieService.deleteAll();
        this.authService.logout();
        this.router.navigate(['/auth/login']);
    }
}
