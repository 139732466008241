import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class WeatherService {
    weatherEnvironment = 'https://api.openweathermap.org/data/2.5/weather'
    weatherForecast = 'https://api.openweathermap.org/data/2.5/forecast'
    searchFore16Url = 'https://aquamonitor.space/api/f/search/forecast16/'
    weatherServUrl = 'https://aquamonitor.space/api/w/road/'
    searchServUrl='https://aquamonitor.space/api/w/search/road/'
    searchForeUrl='https://aquamonitor.space/api/f/search/forecast/'
    forescastServUrl='https://aquamonitor.space/api/f/forecast/'
    forescastServUrl16='https://aquamonitor.space/api/f/forecast16/'
    weatherForecastDate='https://aquamonitor.space/api/f/searchdate/forecast/'
    frontForecast16 = 'https://api.openweathermap.org/data/2.5/forecast/daily/'
    constructor(
        private readonly http: HttpClient,
        private readonly globalToastService: GlobalToastService
        ){}

    public async getWeatherFromRoutePoint(lat:number,lon:number){
        try{
            const data = await this.http.get<any>(`${this.weatherEnvironment}?lat=${lat}&lon=${lon}&lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d`).toPromise<any>();
            return data;
        }catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public getWeather(lat:number,lon:number){
        return this.http.get(`${this.searchServUrl}?lat=${lat}&lon=${lon}`)
        // this.http.get(`${this.weatherEnvironment}?lat=${lat}&lon=${lon}&lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d`)
    }
    public getForecast(lat:number,lon:number){
        // &lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d
        return this.http.get(`${this.searchForeUrl}?lat=${lat}&lon=${lon}`)
    }

    public getForecast16(lat:number,lon:number){
        // &lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d
        return this.http.get(`${this.searchFore16Url}?lat=${lat}&lon=${lon}`)
    }
    public getForecast16byFront(lat:number,lon:number){
        // &lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d
        return this.http.get(`${this.frontForecast16}?lat=${lat}&lon=${lon}&cnt=16&appid=8df66df16051842763f1bb5d78058b6c`)
    }
    public getForecastByDate(lat:number,lon:number, date:Date){
        // &lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d
        return this.http.get(`${this.weatherForecastDate}?lat=${lat}&lon=${lon}&date=${this.formatDate(date)}`)
    }
    public postForecast(lat:number,lon:number){
        const body = {
            'centroid' : {
                'latitude': lat,
                'altitude': lon
            }
        }
        return this.http.post(`${this.forescastServUrl}`,body)
    }

    public currentWeatherfromOpenWeather(lat:number,lon:number)
    {
    
        return this.http.get(`${this.weatherEnvironment}?lat=${lat}&lon=${lon}&lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d`)

    }
    public postForecast16(lat:number,lon:number){
        const body = {
            'centroid' : {
                'latitude': lat,
                'altitude': lon
            }
        }
        return this.http.post(`${this.forescastServUrl16}`,body)
    }
    public getPlaceName(lat:number,lon:number){
        return this.http.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`)
    }
    getTemperature(lat: number, lon: number): Observable<number> {
        return this.getWeather(lat, lon).pipe(
          map((response: any) => response.main.temp),
          catchError((error: any) => {
            console.error('Error obteniendo temperatura:', error);
            return throwError('Error al obtener temperatura');
          })
        );
      }

    getWeatherApi(lat:number,lon:number)
    {
        return this.http.get(`https://aquamonitor.space/api/weather/road/`);
    }

    
    public async getForecastFromRoutePoint(lat:number,lon:number){
        try{
            const data = await this.http.get<any>(`${this.weatherForecast}?lat=${lat}&lon=${lon}&lang=es&units=metric&appid=c7a6e7bb33cc846df596b2ba17c6b32d`).toPromise<any>();
            return data;
        }catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async postRetrieveTemp(lat:number, lon:number, name:string, distance:number){
        const body= {
            'name': name,
            'distance': distance,
            'latitude': lat,
            'altitude': lon
        }

        return await this.http.post<any>(`${this.weatherServUrl}`,body).toPromise<any>();

    }

    public async getRetrieveTemp(){
        return await this.http.get<any>(`${this.weatherServUrl}`).toPromise<any>();
    }
     public formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

}